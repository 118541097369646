<template>
  <div class="header-main header sticky">
    <div class="app-header header-dashboard top-header navbar-collapse ">
      <div class="container">
        <a id="horizontal-navtoggle" class="animated-arrow hor-toggle"><span></span></a><!-- sidebar-toggle-->
        <div class="d-flex">
          <router-link to="/admin" class="header-brand">
            <img src="../../../assets/logo.png" style="height: 55px;" class="header-brand-img desktop-logo "
                 alt="Dashlot logo">
            <img src="../../../assets/logo.png" style="height: 55px;"
                 class="header-brand-img desktop-logo-1 " alt="Dashlot logo">
            <img src="../../../assets/logo.png" style="height: 55px;" class="mobile-logo"
                 alt="Dashlot logo">
            <img src="../../../assets/logo.png" style="height: 55px;" class="mobile-logo-1"
                 alt="Dashlot logo">
          </router-link>

          <div class="d-flex header-right mr-auto">
            <div class="dropdown drop-profile d-flex align-items-center">
              <a href="/" target="_blank" class="btn btn-warning" style="color:#fff;height: max-content;">مشاهده
                سایت</a>
              <a class="nav-link pl-0 leading-none" href="#" data-toggle="dropdown" aria-expanded="false">
                <div class="profile-details mt-1">
                  <!-- <span v-if="$root.profile" class="ml-3 mb-0  fs-15 font-weight-semibold">{{$root.profile.full_name}}</span> -->
                  <!-- <small class="text-muted ml-3">appdeveloper</small> -->
                </div>
                <img class="avatar avatar-md brround" src="../../../assets/images/user-icon.png"
                     alt="image">
              </a>
              <div
                  class="dropdown-menu dropdown-menu-right dropdown-menu-arrow animated bounceInDown w-250">
                <div class="user-profile bg-header-image border-bottom p-3">
                  <div class="user-image text-center">
                    <img class="user-images" src="../../../assets/images/user-icon.png" alt="image">
                  </div>
                  <div class="user-details text-center">
                    <h4 v-if="$root.adminProfile" class="mb-0">{{ $root.adminProfile.name }}</h4>
                    <!-- <p class="mb-1 fs-13 text-white-50">Jonathan@gmail.com</p> -->
                  </div>
                </div>
                <!-- <a class="dropdown-item" href="#">
                    <i class="dropdown-icon mdi mdi-account-outline "></i> Profile
                </a>
                <a class="dropdown-item" href="#">
                    <i class="dropdown-icon  mdi mdi-settings"></i> Settings
                </a>
                <a class="dropdown-item" href="#">
                    <span class="float-left"><span class="badge badge-success">6</span></span>
                    <i class="dropdown-icon mdi  mdi-message-outline"></i> Inbox
                </a>
                <a class="dropdown-item" href="#">
                    <i class="dropdown-icon mdi mdi-comment-check-outline"></i> Message
                </a>
                <div class="dropdown-divider"></div> -->
                <button class="dropdown-item mb-1 text-right cursor-pointer" @click.prevent="changePass">
                  تغییر رمز عبور
                </button>
                <button class="dropdown-item mb-1 text-right cursor-pointer" @click.prevent="logOut">
                  خروج
                </button>

              </div>
            </div><!-- Profile -->

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    logOut() {
      this.$root.deleteCookie('adminAuthorization')
      this.$router.push('/admin/login')
      this.$root.profile = []
      // this.$axios.post(this.$root.baseUrl + '/api/admin/logout')
      // .then(() => {

      //     this.$root.currentAdminPermissions = []
      //
      // })
    },
    changePass() {
      window.swal({
        text: 'رمز عبور جدید را وارد کنید',
        content: "input",
        button: {
          text: "تغییر",
          closeModal: false,
        },
        showCancelButton: true,
      })
          .then(pass => {
            return this.$axios
                .post("/api/admin/change-pass",{
                  password: pass
                })
          }).then((response) => {
        this.$root.success_notification(response.data.message)
        window.swal.close()
      }).catch(e => {
        this.$root.error_notification('عملیات به مشکل خورد')
      })
    }
  }
}
</script>
<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.container {
  max-width: 1200px;
}

.header-dashboard {
  position: fixed !important;
  z-index: 99999;
}

.header-dashboard .nav-link {
  display: flex !important;
}

.header-dashboard .animated-arrow {
  right: 0;
}

.dropdown-item {
  pointer-events: unset;
}
</style>
<style>
.horizontalMenucontainer .horizontal-main {
  /* z-index: 99991 !important; */
  margin-top: 64px !important;
}

/* .header-dashboard .dropdown-menu {
    z-index: 99992 !important;
} */

.header-dashboard .dropdown-menu-arrow::before {
  top: -10px;
}

.desktop-logo {
  width: 126px;
  height: 61px;
}

.header-main .dropdown-menu-arrow.dropdown-menu-right::before,
.header-main .dropdown-menu-arrow.dropdown-menu-right::after {
  right: auto !important;
  left: 12px !important;
}
</style>
